<template>
  <div class="lost-page-container">
    <img src="../assets/images/page_404.png" alt="" />
    <div class="available-text">This page isn’t available</div>
    <div>
      The link you followed may be broken, or the page may have been removed.
    </div>
    <el-button @click="goBack">Go back to the previous page</el-button>

    <div class="back-text" @click="goBack">{{ seconds }}</div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "404",
  setup() {
    const router = useRouter();
    const goHome = () => {
      router.replace({
        path: "/home",
      });
    };
    const goBack = () => {
      router.go(-1);
    };
    let seconds = ref(5);
    let goHomeInterval = ref(null);
    onMounted(() => {
      goHomeInterval = setInterval(() => {
        seconds.value--;
        if (seconds.value === 0) {
          clearInterval(goHomeInterval);
          goHome();
        }
      }, 1000);
    });
    return { goHome, goBack, seconds, goHomeInterval };
  },
});
</script>

<style lang="less" scoped>
.lost-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    margin-top: 60px;
    color: #fff;
    background-color: #031a79;
  }
  .available-text {
    margin-bottom: 20px;
    font-size: 34px;
    font-weight: bold;
  }
  .back-text {
    margin-top: 20px;
    font-size: 30px;
    color: #5DDFFF;
  }
}
</style>
